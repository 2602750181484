import type { IPPioEnvironment } from '@partnerportal.io/applibrary';
import { CookieService } from 'ngx-cookie-service';
import environmentConfigFile from '../config.json';
type IConfigJson = Partial<IPPioEnvironment> & { debuggableHosts?: []; };

// extract config properties which are used in this file but not propogated to `environment`
const { debuggableHosts = [] } = environmentConfigFile as unknown as IConfigJson;
// remove the config properties which are not intended to be passed to environment
delete (environmentConfigFile as unknown as IConfigJson).debuggableHosts;

// TODO: Remove this and update the PPIOEnvironment type
type IPPioEnvironmentExtra = IPPioEnvironment & { fullStoryOrgId: string; commandBarEnabled: boolean; };

const defaults: IPPioEnvironmentExtra = { ...environmentConfigFile } as unknown as IPPioEnvironmentExtra;

const cookieService: CookieService = new CookieService(document, 'browser' as any);

// we are in a debuggable environment if we expressly have set the config to be debuggable,
// or if we are running on a host that is in our configured list of debuggable hosts.
defaults.debuggable ||= debuggableHosts.some(host => window.location.host.includes(host));

// when we are running in a debuggable environment, we allow a cookie to override the API
// url the Web app connects to. This is mainly to allow for localhost development.
if (defaults.debuggable && cookieService.get('ppio_force_apiurl')) {
	defaults.apiUrl = cookieService.get('ppio_force_apiurl');
}

export const environment: Readonly<IPPioWebEnvironment> = {
	...defaults,
	production: defaults.production || false,
	apiUrl: defaults.apiUrl || `https://api.${defaults.rootDomain}`,
	servicesUrl: defaults.servicesUrl || `https://api.${defaults.rootDomain}`,
	auth0Domain: defaults.auth0Domain || `login.${defaults.rootDomain}`,
	auth0Tenant: defaults.auth0Tenant || `partnerportal-${defaults.production ? 'prod' : 'dev'}`,
	jwtAudience: defaults.jwtAudience || undefined,
	googleMapsAutocompleteApiKey: 'AIzaSyDyxfZMfdWxKxOPeLlB_l-xkvdRCotl0iE', // typically speaking, API keys in code are bad. but this key is going to need to be public so there's no major harm here
	commandBarEnabled: defaults.commandBarEnabled ?? true,
};

if (environment.debuggable) console.log('environment', { environmentConfigFile, environment });

export interface IPPioWebEnvironment extends IPPioEnvironment {
	googleMapsAutocompleteApiKey: string;
	fullStoryOrgId: string;
	commandBarEnabled: boolean;
}
